$primary-color: #03A85C;
$primary-color_plus-4: #D1F0E2;
$primary-color-light: lighten($primary-color, 10%);
$primary-text-color: #FFF;
$muted-text-color: #A3A1AC;
$secondary-text-color: #555;

$secondary-color-blue: #19132f;
$secondary-color-blue_plus-1: #474259;
$secondary-color-blue_plus-2: #757182;
$secondary-color-blue_plus-4: #E4E4F2;
$secondary-color-blue_plus-6: #F4F6F8;

$secondary-color: #EFEFEF;
$secondary-color-text-color: #414141;
$secondary-color-light: lighten($secondary-color, 10%);

$gray: #888888;
$darker-gray: #4F4F4F;

$facebook-blue: #3b5998;
$google-red: #d62d20;
$instagram-gradient: radial-gradient(circle at 30% 150%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);


$footerColorDefault: #555;
$footerBackgroundDefault: #ddd;
$footerColorHover: #ddd;

$errorColor: #dc3545;
$successColor: green;

$white: white;

// Need prefix "color-", otherwise it fails compiling
$color-blue: (
    "base": #19132F,
    "+1": #474259,
    "+2": #757182,
    "+3": #A3A1AC,
    "+4": #D1D0D5,
    "+5": #F4F6F8
);

$color-green: (
    "-2": #026738,
    "-1": #028548,
    "base": #03A85C,
    "+1": #04CC76,
    "+2": #05E897,
    "+3": #AFE6D2,
    "+4": #D1F0E2,
);


// New Colors
$gray: #D1D0D5;
$gray-10: lighten($gray, 50%);
$body-bg: #fff;

$primary: #19132F;
$accent:  #03A85C;
