@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
  -o-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin box-shadow($hoff, $voff, $blur, $spread, $color, $option:initial) {
  box-shadow: $hoff $voff $blur $spread $color $option;
  -webkit-box-shadow: $hoff $voff $blur $spread $color $option;
  -moz-box-shadow: $hoff $voff $blur $spread $color $option;
  -o-box-shadow: $hoff $voff $blur $spread $color $option;
  -ms-box-shadow: $hoff $voff $blur $spread $color $option;
}

@mixin box-shadow2($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin transition($time, $effect, $property:null) {
  -webkit-transition: $property $time $effect;
  -moz-transition: $property $time $effect;
  -ms-transition: $property $time $effect;
  -o-transition: $property $time $effect;
  transition: $property $time $effect;
}

@mixin transition-delay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

.image-shadow {
  -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#000')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#000')";
}

.card-shadow {
  @include box-shadow(0, 0, 14px, 2px, rgba(50, 50, 50, 0.56));
}

@mixin noScrollBar() {
  scrollbar-width: none; /* Firefox 64 */

  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin noHrefHighlight() {
  display: block;
  text-decoration: none;
  color: inherit;
}


@mixin unselectable() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}


@mixin darken-bg-on-hover($color) {
  background: $color;

  &:hover {
    background: darken($color, 5%)
  }
}
