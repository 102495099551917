@import "src/colors";
@import "src/media-queries";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.125rem;
}

h4 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

h5 {
  font-size: 0.875rem;
}

h6 {
  font-size: 0.750rem;
}

.text-muted {
  color: $muted-text-color;
  font-weight: 400;
}

.pre-title__uppercase {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.data-policy,
.imprint,
.anb {
  hyphens: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    hyphens: auto;
    overflow: hidden;
  }

  h5 {
    margin-bottom: 5px;
    &.main__title {
      margin-bottom: 64px;
    }
  }

  p {
    font-size: 0.875rem;
  }

  ul,
  ol {
    font-size: 0.875rem;
    padding-inline-start: 20px;
    li {
      p {
        margin-bottom: 1rem;
      }
    }
  }

  ol {
    &.list-alpha {
      &__low {
        list-style-type: lower-alpha;
      }
      &__upper {
        list-style-type: upper-alpha;
        font-weight: 700;
        p {
          font-weight: 400;
        }
      }
    }
  }

  ul {
    ul{
      li {
        p {
          margin-bottom: 0.5rem;
        }
      &:last-of-type {
        margin-bottom: 1rem;
      }
      }
    }
  }

  &__item {
    margin-bottom: 2rem;
  }
}
