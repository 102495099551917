@import "src/colors";
@import "src/media-queries";

// Material Chip
.mat-standard-chip {
  cursor: pointer;
}

.mat-chip-list {
  .mat-chip-list-wrapper {
    .mat-chip {
      &.btn-outlined {
        border: 1px solid $secondary-color-text-color;
        border-radius: 36px;
        background-color: $white;
        font-size: 1em;
        padding: 1.5em 2.5em;
        cursor: pointer;
      }

      &.mat-standard-chip {
        background-color: #474259;
        border-radius: 5px;
        font-size: 0.75rem;
        font-weight: normal;

        min-height: 27px;
        padding: 0 10px;

        @include respond-to-max(md) {
          font-size: 0.75em;
          line-height: 1em;
        }
      }

      &.btn-round-outlined {
        color: $secondary-color-blue;
        border: 2px solid map-get($color-blue , '+4');
        background-color: transparent;

        &[disabled=true] {
          opacity: 1 !important;
        }
      }

      a {
        text-decoration: none;
        color: initial;
      }
    }
  }
}
