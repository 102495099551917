.team-news-tabs.mat-tab-group {
  margin-bottom: 40px;
  &.mat-primary {
    .mat-ink-bar {
      background-color: $primary-color;
      height: 4px;
    }
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-label {
    height: 24px;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 0;
    min-width: auto;
    margin-right: 1.5rem;
    margin-bottom: 0.25rem;

    &-active {
      font-weight: 700;
      opacity: 1 !important;
    }

    &-content {
      color: $primary-text-color !important;
    }
  }

  @include respond-to-max(md) {



  &.mat-primary {
    .mat-ink-bar {
      background-color: white;
      height: 4px;
    }
  }

  .mat-tab-header {
    background-color: $secondary-color-blue;
    margin: 0 -16px;
    height: var(--toolbar-height);
  }

  .mat-tab-label {
    height: 24px;
    font-size: 1rem;
    opacity: 1;
    font-weight: 400;
    margin: 0;
    width: 50%;
    height: var(--toolbar-height);

    &-active {
      font-weight: 700;
      opacity: 1 !important;
    }

    &-content {
      color: white !important;
    }
  }

  }
}

.tab__header {
  display: flex;

  .tab__item {
    margin-right: 30px;
    cursor: pointer;

    & > * {
      cursor: pointer;
    }

    label {
      color: #fff;
      font-size: 1.125rem;
      font-weight: 400;
      opacity: .6;
      margin: 0;
      text-align: center;
    }

    .tab__underline {
      background-color: #03a85c;
      height: 4px;
      visibility: hidden;
    }

    &.active {
      label {
        opacity: 1;
        font-weight: 700;
      }

      .tab__underline {
        visibility: visible;
      }
    }
  }

  @include respond-to-max(md) {
    background-color: $secondary-color-blue;
    margin: 0 -8px;
    height: 50px;

    .tab__item {
      width: 50%;
      margin: 0;


      label {
        color: white;
        font-size: 1rem;
        font-weight: 400;
        opacity: 1;
        margin: 0;
        text-align: center;
        vertical-align: middle;
        height: 50px;
        width: 100%;
        line-height: 50px;
      }

      .tab__underline {
        background-color: white;
        height: 4px;
        visibility: hidden;
      }

      &.active {
        margin: 0;
        label {
          opacity: 1;
          font-weight: 700;
          margin-bottom: -4px;
        }

        .tab__underline {
          visibility: visible;
        }
      }
    }
  }

}


