@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(https://fonts.fan-at.dev/fonts/Material-Icons/MaterialIcons-Regular.woff2) format('woff2'),
  url(https://fonts.fan-at.dev/fonts/Material-Icons/MaterialIcons-Regular.woff) format('woff');
}
