@font-face {
    font-family: 'Roboto Slab';
    src: url('https://fonts.fan-at.dev/fonts/RobotoSlab/RobotoSlab-Bold.woff2') format('woff2'),
        url('https://fonts.fan-at.dev/fonts/RobotoSlab/RobotoSlab-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('https://fonts.fan-at.dev/fonts/RobotoSlab/RobotoSlab-Regular.woff2') format('woff2'),
        url('https://fonts.fan-at.dev/fonts/RobotoSlab/RobotoSlab-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('https://fonts.fan-at.dev/fonts/RobotoSlab/RobotoSlab-Medium.woff2') format('woff2'),
        url('https://fonts.fan-at.dev/fonts/RobotoSlab/RobotoSlab-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}
