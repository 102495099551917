@import "src/colors";
@import "src/media-queries";
@import "src/mixins";


button.mat-stroked-button,
button.mat-flat-button,
button.mat-raised-button {
  border-radius: 36px;
  .mat-icon {
    font-size: 20px;
    line-height: 1em;
    width: 1em;
    height: 1em;
  }
}

// Material Button Selectable
.mat-select-button {
  border-radius: 36px;
  padding: 0.125rem 1rem;
  height: 2rem;
  text-transform: uppercase;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border-image: none;
  border: none;
  font-weight: 400;

  &:not([disabled]) {
      border-color: none;
  }

  &.selected {
    color: $white;
    background-color: map-get($map: $color-blue, $key: "+1");
  }

  .mat-button-wrapper {
      font-size: 0.75rem;
      line-height: 1;
      // font-weight: bold;
  }
}
a.mat-select-button {
  @include noHrefHighlight();

  font-size: 0.75rem;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.buttons-icon-text {
  .mat-button {
    .mat-button-wrapper {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      span {
        font-size: 0.625rem;
        line-height: 1;
        margin-top: 4px;
      }
    }
  }

  &.five-buttons {
    .mat-button {
      width: 20%;
    }
  }
}
