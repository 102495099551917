@font-face {
    font-family: 'Barlow';
    src: url('https://static.fan.at/fonts/Barlow/Barlow-Regular.woff2') format('woff2'),
        url('https://static.fan.at/fonts/Barlow/Barlow-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-Italic.woff2') format('woff2'),
  url('https://static.fan.at/fonts/Barlow/Barlow-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: optional;
}

@font-face {
    font-family: 'Barlow';
    src: url('https://static.fan.at/fonts/Barlow/Barlow-Medium.woff2') format('woff2'),
        url('https://static.fan.at/fonts/Barlow/Barlow-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: optional;
}

@font-face {
    font-family: 'Barlow';
    src: url('https://static.fan.at/fonts/Barlow/Barlow-MediumItalic.woff2') format('woff2'),
        url('https://static.fan.at/fonts/Barlow/Barlow-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-SemiBold.woff2') format('woff2'),
  url('https://static.fan.at/fonts/Barlow/Barlow-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-SemiBoldItalic.woff2') format('woff2'),
  url('https://static.fan.at/fonts/Barlow/Barlow-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-Bold.woff2') format('woff2'),
  url('https://static.fan.at/fonts/Barlow/Barlow-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-BoldItalic.woff2') format('woff2'),
  url('https://static.fan.at/fonts/Barlow/Barlow-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-ExtraBold.woff2') format('woff2'),
       url('https://static.fan.at/fonts/Barlow/Barlow-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-ExtraBoldItalic.woff2') format('woff2'),
  url('https://static.fan.at/fonts/Barlow/Barlow-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-Black.woff2') format('woff2'),
       url('https://static.fan.at/fonts/Barlow/Barlow-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Barlow';
  src: url('https://static.fan.at/fonts/Barlow/Barlow-BlackItalic.woff2') format('woff2'),
  url('https://static.fan.at/fonts/Barlow/Barlow-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: optional;
}

