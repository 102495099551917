@import "src/mixins";
@import "src/colors";

@import "src/media-queries";
@import "projects/shared/shared-styles/material-designs/overlays";

// Material Select Field
.mat-form-field-appearance-outline {
  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: map-get($color-blue , "+2" )
    }

    .mat-input-element {
      caret-color: $primary-text-color;
    }
  }

  &.mat-focused {
    .mat-form-field-label {
      color: map-get($color-blue , "+2" )
    }
  }
}

.mat-input-element {
  caret-color: $primary-text-color;
}

.mat-form-field {
  .mat-form-field-wrapper {

    .mat-form-field-infix {
      // width: 100px;

      .mat-select-trigger {
        .mat-select-arrow-wrapper {
          .mat-select-arrow {
            color: $primary-text-color;
          }
        }
      }
    }
  }

  &.select-field {
    font-size: 0.75rem;
    text-transform: uppercase;
    max-width: 140px;
    margin-right: 0.75rem;
    margin-bottom: 0.25rem;

    &.filled {
      &.ng-valid {
        .mat-form-field-wrapper {
          background-color: $secondary-color-blue_plus-1;

          .mat-select-value {
            color: white;
          }
          .mat-select-arrow {
            color: white !important;
          }
        }
      }
      &.mat-form-field-invalid {
        .mat-form-field-label {
          color: rgba(0, 0, 0, 0.42) !important;
        }
      }
      .mat-form-field-label-wrapper .mat-form-field-label {
        color: rgba(0, 0, 0, 0.42) !important;
      }
      &.active {
        .mat-form-field-wrapper {
          background-color: $secondary-color-blue;
          .mat-select-value {
            span {
              color: $white;
            }
          }
          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: $white;
            }
          }
        }
      }
    }

    .mat-placeholder-required.mat-form-field-required-marker {
      display: none;
    }

    .mat-select-panel {
      display: none;
    }

    .md-select-placeholder {
      transform: none;
    }

    .mat-form-field-wrapper {
      border: 1px solid $primary-text-color;
      border-radius: var(--border-radius);
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;

      .mat-form-field-flex {
        .mat-form-field-infix {
          border-top: none;
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $primary-color;
}

.mat-select-panel {
  max-height: 350px !important;
}

.mat-checkbox-checked.mat-primary div.mat-checkbox-background {
  background: $primary-color;
}

.select-field-dropdown{
  margin-top: 30px !important;
}


// dropdown styles
$dropdownVerticalPadding: 8px;
$dropdownHorizontalPadding: 1em;

.cdk-overlay-container .cdk-overlay-pane .dropdown-selection-panel {
  // position: absolute;
  margin-top: calc(1em + #{$dropdownVerticalPadding} * 2 + 4px);
  min-width: calc(100% + #{$dropdownHorizontalPadding} * 2) !important;
  border-radius: 10px;
}

.dropdown-selection-trigger {
  $backgroundColor: #474259;
  @include darken-bg-on-hover($backgroundColor);
  border-radius: 25px;
  padding: $dropdownVerticalPadding $dropdownHorizontalPadding;
  width: unset !important; // override mat-select style

  .mat-select-value {
    max-width: calc(250px - (#{$dropdownHorizontalPadding} * 2 + 18px));
  }

  .mat-select-placeholder,
  .mat-select-arrow {
    color: #fff;
  }

  &.active {
    $backgroundColor: map-get($color-green, "+1");
    @include darken-bg-on-hover($backgroundColor);

    .mat-select-value {
      color: #fff;
      font-weight: bold;
    }

    .mat-select-placeholder,
    .mat-select-arrow {
      color: #fff;
    }
  }
}
