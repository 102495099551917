@import "./breakpoints";

:root {
  --header-height: 105px;
  --toolbar-height: 60px;
  --nav-height: 45px;
  --border-radius: 10px;
  --bottom-nav-height: 70px;
  --box-shadow: 0 1px 4px #ddd;
}

@include respond-above(md) {
  :root {
    --header-height: 122px;
    --toolbar-height: 72px;
    --nav-height: 50px;
  }
}
