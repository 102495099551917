@font-face {
    font-family: 'Roboto';
    src: url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
        url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
        url('https://fonts.fan-at.dev/fonts/Roboto/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
