/* You can add global styles to this file, and also import other style files */
@import "./bootstrap-theming.scss";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";

// Import Mobile Bootstrap Styling
@import "./bootstrap-mobile.scss";

// Common Imports
@import "src/colors";
@import "src/type";
@import "src/media-queries";
@import "src/mixins";
@import "projects/shared/shared-styles/colors";
@import "projects/shared/shared-styles/variables";
@import "projects/shared/shared-styles/breakpoints";

// Material Design Imports
@import "./material-designs/buttons";
@import "./material-designs/chips";
@import "./material-designs/forms";
@import "./material-designs/tabs";
@import "./material-designs/spinners";

// Fonts
@import "../projects/shared/fonts/roboto-slab";
@import "../projects/shared/fonts/roboto";
@import "../projects/shared/fonts/barlow";
@import "../projects/shared/fonts/material-icons";

html {
  overflow-y: scroll;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-height: 100vh;
  max-width: 100vw;
  margin: 0;
  font-family: Barlow, sans-serif;
  font-size: 16px;
  color: $white;
  background-color: $primary;

  @include respond-to-max(md) {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
}

body.light {
  color: $primary;
  background-color: $white;
}

body.webview {
  user-select: none;
}

body.in-iframe {
  height: unset;
}

@include respond-to(lg) {
  .container {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1650px) {
  .container {
    margin-left: auto;
  }
}

.container.simple {
  margin-left: 0;
}

.container.nested {
  padding: 4px;
  margin-top: 4px;

  @include respond-above(sm) {
    padding: 0;
    margin-top: 12px;
  }
}

button:focus {
  outline: 0;
}

.matches {
  border-radius: var(--border-radius);
  display: block;
  overflow: hidden;

  &-game {
    border-bottom: 1px solid map-get($color-blue , "+5" );
    &:last-of-type {
      border-bottom: none;
    }
  }
}
.card-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.team-info {
  text-decoration: none;
  color: inherit;

  &__section_headline {
    font-size: 1.125em;
    color: $primary-color;
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 32px;
  }
}

.seperator-headline {
  position: relative;
  height: 24px;
  margin-bottom: 1rem;
  &__line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 100%;
    height: 1px;
    z-index: 1;
  }
  h5 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    z-index: 2;
    padding-right: 0.5rem;
  }
}

.top-space {
  padding-top: 32px;
}

.top-space-only-desktop {
  padding-top: 0;

  @include respond-to(md) {
    padding-top: 32px;
  }
}

.no-infos-available {
  margin: 0.5em;
  text-align: center;
  height: 2em;
  line-height: 2em;
  color: #555;
}

.invisible {
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
}

.news-load-more {
  background-color: $label !important;
  color: $primary !important;
  left: 50%;
  transform: translateX(-50%) !important;
}

.clean-horizontal-scroll {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}

.app__justify-text {
  text-align: justify;
}

body.hide-cmp-popup {
  #cookiescript_injected {
    visibility: hidden;
  }
}

// Set height of google ads iframe wrapper
div[id^='google_ads_iframe_/6771346/sfa_fan.at/web.fan.at/'] {
  height: inherit;
  width: inherit;
}

//Cookiescript Cookiebanner
#cookiescript_badge {
  display: none !important;
}

#cookiescript_save, #cookiescript_accept {
  background-color: $primary-color !important;
}

#cookiescript_declaration > .cookiescript_active,
#cookiescript_aboutcookies > .cookiescript_active,
#cookiescript_maintabs > .cookiescript_active{
  color: $primary-color !important;
}

#cookiescript_categories > .cookiescript_active {
  background-color: $primary !important;
  color: $accent !important;
}

#cookiescript_cookietablewrap {
  max-height: 600px !important;
}

#cookiescript_reportwrap {
  height: 300px !important;
}

@media only screen and (max-width: 600px) {
  #cookiescript_maintabs, #cookiescript_tabscontent {
    display: none !important;
  }
  #cookiescript_cookietablewrap {
    min-height: unset !important;
  }
}

// Bitmovin player styles
.bmpui-ui-watermark {
  display: none;
}

.bmpui-container-wrapper {
  padding: 0;
}

.bmpui-seekbar-playbackposition-marker {
  border: .1875em solid $accent !important;
  background-color: $accent !important;
}

.bmpui-seekbar-playbackposition {
  background-color: $accent !important;
}

.bmpui-ui-hugereplaybutton .bmpui-image {
  filter: hue-rotate(300deg) !important;
}

.app__play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: rgba($primary, 0.8);
  height: 54px;
  width: 54px;

  mat-icon {
    color: #ffff;
    font-size: 42px;
    width: 1em;
    height: 1em;
    line-height: 1em;
  }
}

.rise-on-hover,
.rise-on-hover-light,
.slide-right-on-hover,
.scale-on-hover {
  transition: transform 300ms ease-in-out;
}

.scale-on-hover {
  transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out;
}

.rise-on-hover:hover {
  @include respond-to(md) {
    transform: translateY(-4px);
  }
}

.rise-on-hover-light:hover {
  @include respond-to(md) {
    transform: translateY(-2px);
  }
}

.slide-right-on-hover:hover {
  @include respond-to(md) {
    transform: translateX(4px);
  }
}

.scale-on-hover:hover {
  @include respond-to(md) {
    transform: scale(1.03);
    box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.75);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 400ms ease forwards;
}

.fade-in-delay {
  opacity: 0;
  animation: fadeIn 400ms ease 400ms forwards;
}

.loading-skeleton {
  background: $primary-1;
  border-radius: var(--border-radius);
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  height: 450px;
  width: 100%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.mat-dialog__overflow-visible {
  .mat-dialog-container {
    overflow: visible !important;
  }
}

.team-logo, .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
  }
}

.mat-bottom-sheet-container {
  border-radius: 24px 24px 0 0;
}

.small {
  opacity: 0.7;
  margin: 0 8px;
}

// Material Icon Styles
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

.mat-spinner-white circle  {
  stroke: #fff !important;
}

// Material
.mat-spinner-white circle  {
  stroke: #fff !important;
}

.mat-menu-panel,
.mat-menu-item,
.mat-menu-content,
.mat-menu-content,
.mat-select-panel,
.mat-dialog-container,
.mat-bottom-sheet-container {
  background-color: $foreground !important;
  color: $label !important;
}

.mat-snack-bar-container {
  @include respond-below(sm) {
    margin-bottom: calc(var(--bottom-nav-height) + 8px) !important;
  }
}

.mat-simple-snackbar {
  justify-content: center !important;
}

// Taboola
#taboola-below-article-thumbnails {
  padding: 16px;
  background-color: $foreground;
  border-radius: var(--border-radius);
  * {
    color: #fff !important;
    border-color: #fff !important;
  }
}

// Kickback vouchers
.wvouch {
  * {
    color: #fff !important;
  }
  .wvouchOuter {
    background-color: $foreground !important;
    box-shadow: none !important;
    border-radius: var(--border-radius) !important;
    flex-wrap: wrap !important;
    gap: 10px;
  }

  .wvouchItem {
    padding: 0 !important;
  }
}

.staige-player__hide-live-jump {
  .s-player__jump-button {
    display: none;
  }
  .s-player__toast {
    display: none;
  }
}
