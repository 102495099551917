@mixin respond-to($media) {
  $breakSmall: 576px;
  $breakMedium: 768px;
  $breakLarge: 992px;
  $breakExtraLarge: 1200px;

  @if $media == sm {
    @media only screen and (min-width: $breakSmall) { @content; }
  }
  @else if $media == md {
    @media only screen and (min-width: $breakMedium) { @content; }
  }
  @else if $media == lg {
    @media only screen and (min-width: $breakLarge) { @content; }
  }
  @else if $media == xl {
    @media only screen and (min-width: $breakExtraLarge) { @content; }
  }
}

@mixin respond-to-max($media) {
  $breakSmall: 576px;
  $breakMedium: 768px;
  $breakLarge: 992px;
  $breakExtraLarge: 1200px;

  @if $media == sm {
    @media only screen and (max-width: $breakSmall) { @content; }
  }
  @else if $media == md {
    @media only screen and (max-width: $breakMedium) { @content; }
  }
  @else if $media == lg {
    @media only screen and (max-width: $breakLarge) { @content; }
  }
  @else if $media == xl {
    @media only screen and (max-width: $breakExtraLarge) { @content; }
  }
}
