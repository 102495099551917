@import "src/media-queries";

@include respond-to-max(md) {
    [class*="container-"] {
        padding-left: 8px;
        padding-right: 8px;
    }

    .row {
        margin-left: -2px;
        margin-right: -2px;
    }

    .col,
    [class*="col-"] {
        padding-left: 2px;
        padding-right: 2px;
    }
}
