@import "./colors.scss";
@import "bootstrap/scss/functions";

// Bootstrap Theming

$theme-colors: (
  primary: $primary-color,
  secondary: $secondary-color-blue,
);

.container {
  max-width: 960px !important;
}
