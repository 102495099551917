$primary: #19132F;
$primary-1: #302b44; // old: #474259;
$primary-2: #474259;
$primary-3: #A3A1AC;
$primary-4: #5e5a6d;
$primary-5: #E4E4F2;
$primary-6: #F4F6F8;
$primary-light: #A3A1AC;
$primary-alt: #384860;

$accent-dark-2: #026738;
$accent-dark-1: #028548;
$accent:  #03A85C;
$accent-1: #04CC76;
$accent-2: #05E897;
$accent-3: #AFE6D2;
$accent-4: #D1F0E2;
$accent-5: #E9F5F3;

$warn: #f44336;
$attention: #ffc100;
$background: $primary;
$foreground: $primary-1;
$label: #fff;
$label-muted: #cfcfcf;
$live: #F83333;
$hla-red: #E52D3B;

$krone-accent: #e3000f;
$krone-primary: #222;
$krone-background: #F1EBE5;

$facebook: #4267b2;
$twitter: #1B95E0;
$whatsapp: #25D366;

$highlights: #9747FF;
$replay: #517BBB;

$app-shadow: 0 0 4px #ccc;
